import React, { useState, Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import RegisterForm from "./common/RegisterForm";
import WelcomePage from "./common/WelcomePage";
import users from "../apis/users";
import apiUtils from "../apis/utils";

const AnonymousFlow = () => {
 
  const { loginWithRedirect } = useAuth0();

  const [token, setToken] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isTokenConfirmed, setTokenConfirmed] = useState(false);
  const [tokenValidationLoading, setTokenValidationLoading] = useState(false);
  const [registrationLoading, setRegistrationLoading] = useState(false);
  const [tokenCheckError, setTokenCheckError] = useState(false);
  const [registerFormErrors, setRegisterFormErrors] = useState({});
  const [registerFormErrorMessages, setRegisterFormErrorMessages] = useState([]);

  const onTokenEntered = (token, recaptchaRef) => {
    if (token && recaptchaRef && !tokenValidationLoading) {
      setToken(token);
      doSubmit(token, recaptchaRef);
    }
  };

  const onRegisterFormSubmit = (user, recaptchaRef) => {
    if (user && recaptchaRef && !registrationLoading) {
      doRegister(user, recaptchaRef);
    }
  }

  const doSubmit = (token, recaptchaRef) => {
    setTokenValidationLoading(true);
    recaptchaRef.current.executeAsync()
      .then(recaptchaToken => users.findByToken(token, recaptchaToken))
      .then(response => {
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        setTokenConfirmed(true);
      })
      .catch(error => setTokenCheckError(true))
      .finally(() => {
        setTokenValidationLoading(false);
        recaptchaRef?.current?.reset();
      });
  }

  const doRegister = (user, recaptchaRef) => {
    
    setRegistrationLoading(true);

    recaptchaRef.current.executeAsync()
      .then(recaptchaToken => users.register(user, recaptchaToken))
      .then(apiUtils.onResponse(
        data => {console.log('Success!', data); clearErrors(); loginWithRedirect()},
        data => handleValidationErrors(data),
        data => handleClientError(data)
      ))
      .catch(apiUtils.onError(handleError))
      .finally(() => {
        setRegistrationLoading(false);
        recaptchaRef?.current?.reset();
      });
  }

  const handleValidationErrors = (data) => {
    console.log("handleValidationErrors", data);
    let errors = [];
    for (let key in data.errors)
      if (data.errors.hasOwnProperty(key)) 
        errors.push(data.errors[key]);
    console.log("handleValidationErrors 2", errors);
    setRegisterFormErrors(data.errors);
    setRegisterFormErrorMessages(errors);
  };

  const handleClientError = (data) => {
    console.log("handleClientError", data);
    setRegisterFormErrors({ global: data.message });
    setRegisterFormErrorMessages([data.message]);
  };

  const handleError = error => {
    const message = "Oho! Nieoczekiwany błąd :(";
    setRegisterFormErrors({ global: message });
    setRegisterFormErrorMessages([message]);
    throw error;
  };

  const clearErrors = () => {
    setRegisterFormErrors({});
    setRegisterFormErrorMessages([]);
  }

  return (
    <Fragment>
      <div className="ui container" style={{paddingTop: 2 + 'em'}}>
      {
        isTokenConfirmed 
        
        ? <RegisterForm 
            loading={registrationLoading} 
            token={token} 
            firstName={firstName} 
            lastName={lastName} 
            reCaptchaSiteKey={process.env.REACT_APP_RECAPTCHA_REGISTRATION_FORM}
            onFormSubmit={onRegisterFormSubmit}
            errors={registerFormErrors}
            errorMessages={registerFormErrorMessages} /> 

        : <WelcomePage 
            loading={tokenValidationLoading} 
            reCaptchaSiteKey={process.env.REACT_APP_RECAPTCHA_REGISTRATION_TOKEN_FORM}
            onTokenEntered={onTokenEntered}
            error={tokenCheckError} />
      }
      </div>
    </Fragment>
  );
}

export default AnonymousFlow;