import React, { useState, useEffect } from "react";
import {
  BrowserRouter
} from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useAuth0 } from "@auth0/auth0-react";
import { setAxiosTokenInterceptor } from "../apis/milesrank";
import { saveUser } from "../core/Auth";
import AnonymousFlow from "./AnonymousFlow";
import './App.css';
import { useMobileDetection } from "../hooks/MobileDetecton";
import DesktopLayout from "./layouts/DesktopLayout";
import MobileLayout from "./layouts/MobileLayout";

const App = () => {

  const isMobile = useMobileDetection();

  const { logout, user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const onLogout = () => {
    logout({ returnTo: window.location.origin });
  }

  const [tokenAcquired, setTokenAcquired] = useState(false);

  useEffect(() => {

    if (isAuthenticated) {
      saveUser(user);
      getAccessTokenSilently({audience: process.env.REACT_APP_AUTH0_AUDIENCE})
        .then(accessToken => {
          setAxiosTokenInterceptor(accessToken)
          setTokenAcquired(true);
        })
        .catch(error => console.log(error));
    }

  }, [getAccessTokenSilently, isAuthenticated, user]);

  if (isLoading) {
    return (
      <div className="ui active inverted text loader">
        <FormattedMessage id="loading.auth" defaultMessage="loading..." />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <AnonymousFlow />;
  }

  if (!tokenAcquired) {
    return (
      <div className="ui active inverted text loader">
        <FormattedMessage id="loading.auth.token" defaultMessage="acquiring token..." />
      </div>
    );
  }

  return (
    <BrowserRouter>
      {
      isMobile 
      ? <MobileLayout isAuthenticated={isAuthenticated} onLogout={onLogout} />
      : <DesktopLayout isAuthenticated={isAuthenticated} onLogout={onLogout} />
      }
    </BrowserRouter>
  );
}

export default App;
